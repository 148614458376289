@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-base text-white bg-main-black-50;
    font-family: "IBM Plex Sans", sans-serif !important;
    background-image: url('../public/bg-main.png');
    background-position: top;
    background-repeat: repeat;
    background-size: auto;
  }
}

@layer components {
  .btn {
    @apply min-h-10 py-2 px-3 rounded-md outline-none flex items-center justify-center gap-3 font-semibold;
    border: 1px solid #95B9FF;
    background: linear-gradient(79deg, #95A4FC 1.25%, #7087FF 72.28%);
  }

  .btn:disabled, .btn[disabled] {
    @apply pointer-events-none cursor-not-allowed !border-main-black-200 opacity-50;
    background: #222;
  }

  .btn img {
    @apply w-6 h-auto object-cover;
  }
}